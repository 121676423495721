<template>
  <div class="container professions-container">
    <div class="row page-header">
      <div class="col">
        <h1>Professions</h1>
      </div>
      <div
        class="col text-right"
        v-if="$can({ key: 'professions', expectedPermission: 'create' })"
      >
        <router-link
          :to="{ name: 'professions.add' }"
          class="btn btn-sm btn-outline-blue mb-3 mb-lg-0"
        >
          <icon type="plus" /> Add New Profession
        </router-link>
      </div>
    </div>
    <alert v-if="professions.isLoading" class="light-shadow" />
    <div
      class="
        card
        theme-card
        mobile-tablet-no-card
        border-lg-0
        table-container
        p-0
      "
      v-if="!professions.isLoading && professions.data.length > 0"
    >
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Code</th>
            <th class="text-center">Practitioner</th>
            <th class="text-center">Regulated</th>
            <th class="text-center desktop-only">Unregulated</th>
            <th
              class="text-center"
              v-if="$can({ key: 'professions', expectedPermission: 'edit' })"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in professions.data" :key="p.id">
            <td><b class="mobile-tablet-only mr-2">Name:</b>{{ p.name }}</td>
            <td><b class="mobile-tablet-only mr-2">Code:</b>{{ p.code }}</td>
            <td class="text-center">
              <b class="mobile-tablet-only mr-2">Practitioner:</b>
              <icon
                :type="p.isPractitioner ? 'check' : 'times'"
                :class="p.isPractitioner ? 'text-success' : 'text-danger'"
              />
            </td>
            <td class="text-center">
              <b class="mobile-tablet-only mr-2">Regulated:</b>
              <icon
                :type="p.isRegulated ? 'check' : 'times'"
                :class="p.isRegulated ? 'text-success' : 'text-danger'"
              />
            </td>
            <td class="text-center desktop-only">
              <icon
                :type="!p.isRegulated ? 'check' : 'times'"
                :class="!p.isRegulated ? 'text-success' : 'text-danger'"
              />
            </td>
            <td
              class="text-center td-actions"
              v-if="$can({ key: 'professions', expectedPermission: 'edit' })"
            >
              <button
                class="btn btn-sm btn-black p-2 border-0"
                @click="editProfession(p.id)"
              >
                <b class="mobile-tablet-only mr-2">Edit</b>
                <i class="fa fa-edit actionIcons"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <alert
      class="shadow-card"
      v-if="!professions.isLoading && !professions.data.length"
      :hideLoader="true"
      >No Professions</alert
    >
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Professions",
  computed: {
    ...mapState(["professions"]),
  },
  mounted() {
    this.getProfessions();
  },
  methods: {
    ...mapActions({
      getProfessions: "professions/all",
    }),
    editProfession: function (id) {
      this.$router.push({ path: `/admin/professions/${id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.professions-container {
  @media (max-width: 1024px) {
    .page-header {
      display: block !important;
    }
  }
}
</style>
